const TestSituations = {
  PENDING: { 
    id: 0, 
    name: 'Pendente' 
  },

  APPROVED: { 
    id: 1,
    name: 'Aprovado' 
  },

  DISAPPROVED: { 
    id: 2, 
    name: 'Reprovado' 
  },

  ABSENT: { 
    id: 3, 
    name: 'Ausente' 
  },

  MATRICULATED: {
    id: 4,
    name: 'Matriculado'
  },

  NONE: {
    id: 5,
    name: 'Nenhuma Marcação'
  }
}

Object.freeze(TestSituations)

export default TestSituations