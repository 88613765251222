export default class WaitingListCriteria {
  constructor({
    localTrainingId,
    modalityId,
    result,
    birthYear,
    schoolTerm,
    name,
    dateLastContactTest,
    deficiencyTypeId,
    cidCode,
    presetClass
  } = {}) {
    this.localTrainingId = localTrainingId,
    this.modalityId = modalityId,
    this.result = result,
    this.birthYear = birthYear,
    this.schoolTerm = schoolTerm,
    this.name = name,
    this.dateLastContactTest = dateLastContactTest,
    this.deficiencyTypeId = deficiencyTypeId
    this.cidCode = cidCode
    this.presetClass = presetClass
  }
}